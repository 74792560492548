import { Validator } from "vee-validate"

Validator.extend("speedTestTurns", {
  validate: (value) => {
    if (value.length > 0) {
      return true
    }
    return false
  },
  getMessage: (field, args) => {
    return `Please chose at least one TURN server closest to the location of the test.`
  },
})
