<template>
  <v-dialog v-model="dialog" persistent fullscreen key="dialog-speed-test">
    <v-card data-testid="container-speed-test">
      <v-card-title>
        <span
          class="headline"
          v-html="getTitle()"
          data-testid="title-speed-test"
        ></span>
      </v-card-title>
      <v-card-text>
        <v-form data-vv-scope="form-speed-test" data-testid="form-speed-test">
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="activeSpeedTest.name"
                  :error-messages="errors.collect('form-speed-test.name')"
                  label="Name"
                  placeholder=""
                  data-vv-name="name"
                  data-vv-validate-on="change"
                  outlined
                  small
                  v-validate="'required|max:50'"
                  :rules="uniqueNameRules"
                  data-testid="name"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  outlined
                  v-model="selectedSignalServer"
                  :items="signalServersList"
                  item-text="data.name"
                  return-object
                  :menu-props="{ maxHeight: '400' }"
                  label="Signal server"
                  hint="Signal server"
                  persistent-hint
                  :error-messages="
                    errors.collect('form-speed-test.selectedSignal')
                  "
                  data-vv-name="selectedSignal"
                  v-validate="'required'"
                  :disabled="!$can('read', 'Superadmin')"
                  data-testid="signal-server"
                />
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="selectedTurnServers"
                  :items="activeTurnsList"
                  outlined
                  chips
                  label="TURN servers"
                  multiple
                  item-text="data.name"
                  return-object
                  no-data-text="No server selected"
                  small-chips
                  @change="turnServersChange()"
                  data-vv-name="selectedTurns"
                  data-vv-validate-on="change"
                  :error-messages="
                    errors.collect('form-speed-test.selectedTurns')
                  "
                  v-validate="'speedTestTurns|required'"
                  data-testid="turn-servers"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="activeSpeedTest.duration"
                  label="Test time"
                  suffix="min"
                  outlined
                  type="number"
                  :error-messages="errors.collect('form-speed-test.duration')"
                  data-vv-name="duration"
                  v-validate="'required'"
                  min="1"
                  max="30"
                  @input="checkDurationLimit(activeSpeedTest.duration)"
                  :rules="durationRules"
                  data-testid="duration"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="activeSpeedTest.bandwidth"
                  label="Bandwidth"
                  suffix="Mbps"
                  outlined
                  type="number"
                  :error-messages="errors.collect('form-speed-test.bandwidth')"
                  data-vv-name="bandwidth"
                  v-validate="'required'"
                  min="0"
                  data-testid="bandwidth"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="activeSpeedTest.description"
                  :error-messages="
                    errors.collect('form-speed-test.description')
                  "
                  label="Description"
                  data-vv-name="description"
                  data-vv-validate-on="change"
                  outlined
                  v-validate="'required'"
                  data-testid="description"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          text
          @click.native="closeDialog()"
          data-testid="btn-close"
        >
          Close
        </v-btn>

        <v-btn
          color="indigo"
          dark
          text
          v-if="!testInProgress && !activeSpeedTest.finishedLocal"
          class="ml-1"
          @click="toTestPageUrl()"
          data-testid="btn-test-page"
        >
          Test page
          <v-icon dark right>mdi-chart-areaspline</v-icon>
        </v-btn>

        <!-- v-if="!testInProgress && activeSpeedTest.finished" -->
        <!-- <v-btn
          color="indigo" dark text
          v-if="!testInProgress"
          :to="`/engines/speed-test/run/${editedIndex}`"
          class="ml-1"
        >
          View result
          <v-icon dark right>mdi-chart-areaspline</v-icon>
        </v-btn> -->

        <v-btn
          color="green darken-3"
          class="ml-1"
          dark
          text
          v-if="!testInProgress"
          @click="save()"
          data-testid="btn-save"
        >
          Save
          <v-icon dark class="pl-1">mdi-content-save</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex"
import jsSHA from "jssha"
import _ from "lodash"
import { getCompleteUrl } from "@/common/webrtc/helpers"
import STCommonMixin from "@/mixins/speed-test/common"
import WsConnMixin from "@/mixins/websocket-connection.js"
import "@/validators/engines/speed-test/speed-test-validator"

export default {
  name: "speed-test-dialog",

  props: {
    editedIndex: {
      type: [Number, String],
      default: -1,
    },
  },

  mixins: [WsConnMixin, STCommonMixin],

  data() {
    return {
      show: false,
      maxDuration: 30,
      maxDurationAllServers: 30,
      durationRules: [
        (v) => !!v || "Required",
        (v) =>
          v <= this.maxDuration || `Max duration is ${this.maxDuration} min`,
        (v) =>
          v * this.serverNumbers <= this.maxDurationAllServers ||
          `Maximum test amount for all servers is ${this.maxDurationAllServers} min. Please reduce test time.`,
      ],
      uniqueNameRules: [
        (v) => !!v || "Test name is required",
        (v) => !this.isNameUnique(v) || "This name is already taken.",
      ],
      turnsRules: [
        (v) => v.length < 1 || "Required",
        (v) =>
          v.length > 0 ||
          `Please chose at least one TURN server closest to the location of the test.`,
      ],
      dialog: true,
      allowedSignalServerName: "",
    }
  },

  created() {
    let self = this
    self.$store.dispatch("saveLoaderAction", true)
    self.getItemData().then(() => {
      self.$store.dispatch("saveLoaderAction", false)
    })
    self.$root.$on("closeDialog", () => {
      self.closeDialog()
    })
    self.$root.$on("stopTest", () => {
      self.stopTest()
    })
    if (self.editedIndex == -1) {
      self.$store.dispatch("speedTestResetData")
    }
  },

  mounted() {
    let self = this
    self.getTurnServersList()
    self.getSignalServersList()
  },

  methods: {
    isNameUnique(string) {
      // return _.includes(this.testNames, string); //@todo later do this
      return false
    },
    checkDurationLimit(duration) {
      if (duration > this.maxDuration) {
        setTimeout(() => {
          this.activeSpeedTest.duration = this.maxDuration
        }, 1000)
      }
    },
    turnServersChange() {
      let maxDuration = 30
      if (this.activeSpeedTest.turn_server_urls.length > 0) {
        maxDuration = parseFloat(
          (30 / (this.activeSpeedTest.turn_server_urls.length + 1)).toFixed(0)
        )
        this.activeSpeedTest.duration = maxDuration
      }
      this.maxDuration = maxDuration
    },

    getUniqueHash() {
      let shaObj = new jsSHA("SHA-256", "TEXT")
      shaObj.update("Room ")
      return shaObj.getHash("HEX")
    },

    getTitle() {
      return this.editedIndex == -1
        ? "Add new test"
        : `<span class="blue-grey--text text--lighten-4">Edit test</span> ${this.activeSpeedTest.name}`
    },

    createTest(formData, closeDialog) {
      let self = this
      return new Promise(function (resolve, reject) {
        self.$validator.errors.clear("form-speed-test")
        self.$validator.validateAll("form-speed-test").then((result) => {
          if (result) {
            self.$store
              .dispatch("speedTestCreateAction", formData)
              .then((response) => {
                //self.$emit('data-saved');
                self.editedIndex = _.get(response, "data.data.test_room._key")
                if (closeDialog) {
                  self.closeDialog()
                }
                let params = {
                  text:
                    "Speed Test " +
                    self.activeSpeedTest.name +
                    " was created successfully.",
                  show: true,
                }
                self.$root.$emit("snackbar", params)
                self.activeSpeedTest.name = null
                self.activeSpeedTest.description = null
                resolve()
              })
              .catch((error) => {
                self.handleValidationErrors(error.response, "form-speed-test")
                reject()
              })
          }
        })
      })
    },

    editTest(data, closeDialog) {
      let self = this
      return new Promise(function (resolve, reject) {
        self.$validator.errors.clear("form-speed-test")
        self.$validator.validateAll("form-speed-test").then((result) => {
          if (result) {
            self.$store
              .dispatch("speedTestEditAction", data)
              .then(({ data }) => {
                //self.$emit('data-saved');
                if (closeDialog) {
                  self.closeDialog()
                }
                let params = {
                  text:
                    "Speed Test " +
                    self.activeSpeedTest.name +
                    " was edited successfully.",
                  show: true,
                }
                self.$root.$emit("snackbar", params)
                resolve()
              })
              .catch((error) => {
                self.handleValidationErrors(error.response, "form-speed-test")
                reject()
              })
          }
        })
      })
    },

    save(closeDialog = true) {
      let self = this
      let formData = {
        data: {
          name: self.activeSpeedTest.name,
          description: self.activeSpeedTest.description,
          signal_server_url: self.activeSpeedTest.signal_server_url,
          turn_server_urls: self.activeSpeedTest.turn_server_urls,
          duration: parseInt(self.activeSpeedTest.duration),
          bandwidth: parseInt(self.activeSpeedTest.bandwidth),
          stats: self.activeSpeedTest.stats,
          source: self.activeSpeedTest.source,
          destination: self.activeSpeedTest.destination,
        },
      }
      if (self.editedIndex != -1) {
        formData["slug"] = self.editedIndex
        return self.editTest(formData, closeDialog)
      } else {
        return self.createTest(formData, closeDialog)
      }
    },
    doCopy: function () {
      var text = window.location.href + "/" + this.activeSpeedTest._key
      navigator.clipboard.writeText(text).then(
        function () {
          console.log("Async: Copying to clipboard was successful!")
        },
        function (err) {
          console.error("Async: Could not copy text: ", err)
        }
      )
    },
    stopTest() {
      let self = this
      self.$refs.runTestBL.stopTest(true)
      self.$store.dispatch("speedTestSaveWorkerTasksAction", []).then(() => {
        self.$store
          .dispatch("saveInitializeSpeedTestAction", {
            testInProgress: false,
            isTestInitiator: false,
          })
          .then(() => {})
      })
    },
    runTest() {
      this.$refs.runTestBL.runTestFromDialog()
    },
    resetData() {
      this.activeSpeedTest.name = null
      // this.activeSpeedTest.description = null;
      // this.activeSpeedTest.signal_server_url = null;
      // this.activeSpeedTest.turn_server_urls = null;
      // this.activeSpeedTest.duration = null;
      // this.activeSpeedTest.bandwidth = null;
      // this.activeSpeedTest.stats = null;
      // this.activeSpeedTest.finished = null;
      // this.activeSpeedTest.source = null;
      // this.activeSpeedTest.destination = null;
    },
    toTestPageUrl() {
      let self = this
      self.dialog = false
      self.save(false).then(() => {
        self.$router
          .push({
            path: `/engines/speed-test/run/${self.editedIndex}`,
          })
          .catch((err) => {})
      })
    },
  },
  computed: {
    ...mapGetters({
      //dialogStatus: 'getDialog',
      testInProgress: "getTestInProgres",
      roomMembers: "getRoomMembersWsConn",
      activeSpeedTest: "getActiveSpeedTest",
      activeTurnsList: "getFilledTurnsList",
      modelWebengine: "getModelWebengine",
      existWebrtcPartenerSender: "existWebrtcPartenerSender",
      showLoader: "getShowLoader",
      roomCliens: "getSpeedTestRoomClients",
      showDialogTestOnlyTurns: "showDialogTestOnlyTurns",
      showDialogClientNotAllowed: "showDialogClientNotAllowed",
      websocket_common_conn: "getWebsocketCommonConnection",
      companyInfo: "getCurrentUserCompanyInfo",
      signalServersList: "signalServersList",
    }),
    selectedSignalServer: {
      get() {
        return this.$store.getters.getSelectedSignalByUrl(this.editedIndex)
      },
      set(newValue) {
        let sUrl = getCompleteUrl(newValue.data)
        this.$store.dispatch("speedTestSaveSignalUrlAction", sUrl)
      },
    },
    selectedTurnServers: {
      get() {
        return this.$store.getters.getSelectedTurnsByUrls
      },
      set(newValue) {
        let tUrls = _.map(newValue, function (o) {
          return getCompleteUrl(o.data)
        })
        this.$store.dispatch("speedTestSaveTurnsUrlsAction", tUrls)
      },
    },
    serverNumbers() {
      let nrSignals = this.selectedSignalServer ? 1 : 0
      let nrTurns = this.selectedTurnServers
        ? parseInt(this.selectedTurnServers.length)
        : 0
      return nrSignals + nrTurns
    },
  },
}
</script>

<style lang="scss">
.dialog-speed-test {
  overflow-y: scroll;
}
</style>
